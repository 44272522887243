import React from "react";
import { View, Text } from "react-native";

export default function ExercisesScreen(): JSX.Element {
  return (
    <View>
      <Text>Exercises</Text>
      <View />
    </View>
  );
}
